import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про нас | Відкрийте для себе поля GoalLine
			</title>
			<meta name={"description"} content={"Напиши свою футбольну історію, заб'єш свою мрію - GoalLine Fields, де кожен матч запалює пристрасть до красивої гри"} />
			<meta property={"og:title"} content={"Про нас | Відкрийте для себе поля GoalLine"} />
			<meta property={"og:description"} content={"Напиши свою футбольну історію, заб'єш свою мрію - GoalLine Fields, де кожен матч запалює пристрасть до красивої гри"} />
			<meta property={"og:image"} content={"https://puresglow.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://puresglow.com/img/867329.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://puresglow.com/img/867329.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://puresglow.com/img/867329.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://puresglow.com/img/867329.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://puresglow.com/img/867329.png"} />
			<meta name={"msapplication-TileImage"} content={"https://puresglow.com/img/867329.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Наша сутність

				</Text>
				<Text font="--lead" color="--darkL2" max-width="600px">
				GoalLine Fields - це не просто місце для гри у футбол, це святилище, де любов до гри оживає. Розташована в самому серці громади, наша суть полягає в наданні простору, де гравці всіх рівнів можуть збиратися разом, щоб поділитися своєю пристрастю до футболу та створити незабутні спогади.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://puresglow.com/img/5.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="20px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Пульс полів GoalLine
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Народившись зі спільної любові до футболу, GoalLine Fields є свідченням духу товариськості та спортивної майстерності. Незалежно від того, чи це дружня гра, чи змагальний матч, серцебиття полів GoalLine Fields резонує з азартом та енергією прекрасної гри.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="20px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="20px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Чому наша суть має значення
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Найкращі умови: Наші ультрасучасні поля мають високоякісне ігрове покриття, що гарантує безперебійну та приємну гру для всіх гравців.
						<br/>
Атмосфера спільноти: На полях GoalLine Fields ви не просто гравець - ви частина яскравої та привітної футбольної спільноти, де куються дружні стосунки та створюються спогади.
						<br/>
Зручне розташування: Розташований у самому центрі [Insert City], GoalLine Fields легкодоступний, що робить його ідеальним місцем для гравців та глядачів.

						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://puresglow.com/img/6.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="20px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://puresglow.com/img/7.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="20px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Створіть свою футбольну історію
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Приєднуйтесь до нас на GoalLine Fields і станьте частиною футбольної історії. Незалежно від того, переслідуєте ви голи, відбиваєте удари чи вболіваєте на трибунах, кожна мить на GoalLine Fields - це глава у вашій футбольній мандрівці.
						</Text>
					</Box>
				</Box>
			</LinkBox>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});